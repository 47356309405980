import React from 'react';

const FaqItem = ({ question, answer, className }) => (
  <div className={className}>
    <details>
      <summary>
        <span className="summary-title">{question}</span>
        <div className="summary-chevron-up">
          <svg
            className="feather feather-chevron-down"
            fill="none"
            height="24"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg">
            <polyline points="6 9 12 15 18 9" />
          </svg>
        </div>
      </summary>
      <div className="summary-content">
        <div dangerouslySetInnerHTML={{ __html: `${answer}` }} className="px-4 py-5 sm:p-0" />
      </div>
      <div className="summary-chevron-down">
        <svg
          className="feather feather-chevron-up"
          fill="none"
          height="24"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg">
          <polyline points="18 15 12 9 6 15" />
        </svg>
      </div>
    </details>
  </div>
);

export default FaqItem;
