import React from 'react';
import PropTypes from 'prop-types';

import FaqItem from 'components/FaqItem';
import Layout from 'components/Layout';
import SEO from 'components/Seo';

const FaqPage = ({ location }) => (
  <Layout>
    <SEO
      keywords={['kopirun', 'delivery', 'food delivery', 'crowdsource', 'faq']}
      path={location.pathname}
      title="FAQ"
    />
    <div className="max-w-7xl w-full mx-auto">
      <section className="flex-1 px-4 mt-10 sm:px-6 lg:px-8">
        <h2 className="inline-block mb-4 text-2xl font-bold">FAQ</h2>

        <div className="flex-1 items-center">
          <FaqItem
            answer="KopiRun is your everyday dabao app. With KopiRun, you can create group buys, join group buys and make requests. With a minimal order fee (set by the one starting the KopiRun), you can get your items without worrying about high delivery fees, minimum orders and price hikes OR you can help someone out by starting a group buy and earn from the minimal order fee."
            className="py-2"
            question="What is KopiRun?"
          />
          <FaqItem
            answer="Group buying, involves people in the same estate banding together to place bulk orders for items (e.g., groceries, online shopping, food) while saving on delivery costs and potentially enjoying discounts from suppliers."
            className="py-2"
            question="What is a group buy?"
          />
          <FaqItem
            answer="For now, payment methods will be discussed and decided between you and the KopiRunner/KopiHitcher. Stay tuned for more updates! "
            className="py-2"
            question="How do I make payment?"
          />
          <FaqItem
            answer={
              'Please email us at <a href="mailto:support@kopirun.com" rel="nofollow noopener noreferrer" style="color: blue;" target="_blank">support@kopirun.com</a> so that our team can assist you.'
            }
            className="py-2"
            question="I’ve encountered problems with the app, what should I do?"
          />
          <FaqItem
            answer="If you are below 18, you will require parental consent before downloading."
            className="py-2"
            question="What is the minimum age to download?"
          />
          <FaqItem
            answer={
              'Please email us at <a href="mailto:support@kopirun.com" rel="nofollow noopener noreferrer" style="color: blue;" target="_blank">support@kopirun.com</a> So our team can work something out with you.'
            }
            className="py-2"
            question="I am a Merchant and I would like to collaborate, how do I join?"
          />
        </div>
      </section>
    </div>
  </Layout>
);

FaqPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default FaqPage;
